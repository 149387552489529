import React from 'react';

import { Icon, IconProps } from '@whiteaway/ui';

interface Props extends IconProps {}

/**
 * `NotFoundIcon` is a custom SVG icon component used to represent scenarios where a resource or page is not found.
 */
export const NotFoundIcon = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  return (
    <Icon viewBox="0 0 144 116" ref={ref} {...props}>
      <path d="M128 92V64h2v30h-27v-2h25Z" fill="#000" />
      <path d="M27 32h77v84H27V99h2v15h73V34H29v21h-2V32Z" fill="#000" />
      <path
        d="M102 48.5H29v-2h73v2Zm-43-28a6.5 6.5 0 0 1 6.5-6.5h25a6.5 6.5 0 1 1 0 13h-25a6.5 6.5 0 0 1-6.5-6.5Zm6.5-4.5a4.5 4.5 0 1 0 0 9h25a4.5 4.5 0 1 0 0-9h-25Z"
        fill="#000"
      />
      <path
        d="M59 29.5a4.5 4.5 0 0 1 4.5-4.5h29a4.5 4.5 0 1 1 0 9h-29a4.5 4.5 0 0 1-4.5-4.5Zm4.5-2.5a2.5 2.5 0 0 0 0 5h29a2.5 2.5 0 0 0 0-5h-29ZM40 5h14v29H40V5Zm2 2v25h10V11.126l-4.06.484L47.324 7H42Zm7.342 0 .319 2.39L52 9.112V7h-2.658Z"
        fill="#000"
      />
      <path d="M45 15h4v15h-4V15Z" fill="#000" />
      <path
        d="M65 20.5a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H66a1 1 0 0 1-1-1ZM58 41a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm6 0a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm1 21c-8.284 0-15 6.716-15 15a14.97 14.97 0 0 0 5.636 11.72l-1.25 1.56A16.97 16.97 0 0 1 48 77c0-9.389 7.611-17 17-17s17 7.611 17 17c0 2.084-.375 4.082-1.063 5.929l-1.874-.698c.605-1.627.937-3.39.937-5.231 0-8.284-6.716-15-15-15Zm-38 0c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15ZM10 77c0-9.389 7.611-17 17-17s17 7.611 17 17-7.611 17-17 17-17-7.611-17-17Z"
        fill="#000"
      />
      <path
        d="M65 56c-7.772 0-14.559 4.221-18.191 10.5l-1.731-1C49.053 58.626 56.485 54 65 54c12.703 0 23 10.297 23 23 0 5.083-1.65 9.784-4.444 13.591l-1.612-1.182A20.901 20.901 0 0 0 86 77c0-11.598-9.402-21-21-21ZM46.809 87.5a21.102 21.102 0 0 0 8.46 8.114l-.928 1.772a23.103 23.103 0 0 1-9.263-8.885l1.73-1.002Z"
        fill="#000"
      />
      <path
        d="M27 56c-9.26 0-17.123 5.995-19.915 14.318l-1.896-.636C8.245 60.568 16.854 54 27 54c12.703 0 23 10.297 23 23s-10.297 23-23 23c-9.753 0-18.086-6.07-21.431-14.636l1.862-.727C10.488 92.46 18.099 98 27 98c11.598 0 21-9.402 21-21s-9.402-21-21-21Z"
        fill="#000"
      />
      <path
        d="M0 69h12v2H2v13h11.5v2H0V69Zm72.603 7.004c3.228-.275 5.81 1.087 6.791 3.049 1.356 2.71 3.232 7.121 4.47 11.58.618 2.229 1.085 4.497 1.24 6.587.153 2.074.006 4.062-.685 5.674-.81 1.889-1.852 3.084-3.087 3.691-1.239.609-2.504.545-3.59.256-1.072-.286-2.043-.812-2.758-1.225l-.385-.225a17.073 17.073 0 0 0-.44-.254c-.954.714-1.795 1.25-2.565 1.585-.91.395-1.787.538-2.661.308-.848-.224-1.548-.761-2.188-1.418-.639-.656-1.302-1.521-2.045-2.512-.83-1.108-2.396-1.302-4.493-.731-1.488.404-3.093.101-4.316-.673-1.233-.78-2.177-2.115-2.095-3.794l.715-14.661.095-.188c.737-1.474 2.226-3.29 4.108-4.203.96-.465 2.062-.713 3.224-.506.908.161 1.788.589 2.614 1.305 2.913-2.364 5.67-3.443 8.05-3.645Zm-9.57 4.985c-.514-.397-1-.597-1.446-.676-.655-.117-1.328.01-2 .337-1.316.637-2.475 1.963-3.099 3.117L55.794 98c-.04.8.394 1.518 1.167 2.007.783.496 1.813.68 2.72.433 2.244-.61 4.991-.71 6.619 1.461.757 1.009 1.344 1.769 1.877 2.316.532.546.926.79 1.265.879.314.083.718.069 1.355-.208.656-.285 1.483-.816 2.59-1.676l.27-.211H74c.259 0 .483.081.612.132.148.06.299.136.437.212.177.096.39.221.607.348.11.065.22.13.329.192.691.4 1.47.811 2.273 1.025.79.21 1.524.209 2.191-.119.672-.33 1.442-1.073 2.132-2.684.51-1.189.67-2.808.528-4.739-.141-1.914-.575-4.046-1.172-6.2-1.196-4.308-3.02-8.597-4.331-11.22-.52-1.038-2.187-2.176-4.834-1.95-2.61.221-6.14 1.796-10.025 6.167a1 1 0 1 1-1.494-1.328 30.827 30.827 0 0 1 1.78-1.847Z"
        fill="#000"
      />
      <path
        d="M65 92a1 1 0 0 1 1 1v9a1 1 0 0 1-2 0v-9a1 1 0 0 1 1-1Zm7.933-3.998a1 1 0 0 1 1.065.931l1 15a1 1 0 0 1-1.996.134l-1-15a1 1 0 0 1 .931-1.065ZM32 36h18v9H32v-9Zm2 2v5h14v-5H34Z"
        fill="#000"
      />
      <path d="M97 40a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-11 .5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138 47h-18v8a9 9 0 0 0 9 9 9 9 0 0 0 9-9v-8Zm-20-2v10c0 6.075 4.925 11 11 11s11-4.925 11-11V45h-22Zm11-17c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13Zm0 2c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
        fill="#000"
      />
      <path
        d="M124 11a1 1 0 0 1 1 1v6.5a1 1 0 0 1-2 0V12a1 1 0 0 1 1-1Zm0 23a1 1 0 0 1 1 1v11a1 1 0 0 1-2 0V35a1 1 0 0 1 1-1Zm10-23a1 1 0 0 1 1 1v6.5a1 1 0 0 1-2 0V12a1 1 0 0 1 1-1Zm0 23a1 1 0 0 1 1 1v11a1 1 0 0 1-2 0V35a1 1 0 0 1 1-1Z"
        fill="#000"
      />
    </Icon>
  );
});

NotFoundIcon.displayName = 'NotFoundIcon';

export default NotFoundIcon;
