'use client';

import styled from 'styled-components';

import { NotFound } from '@/components';

/**
 * This component renders a custom "Not Found" page for the application. It is used to handle
 * cases where a user navigates to a route that does not exist (404 error).
 */
export const AppNotFound: React.FC = () => {
  return (
    <Root>
      <NotFound />
    </Root>
  );
};

AppNotFound.displayName = 'AppNotFound';

export default AppNotFound;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;
