import styled, { css } from 'styled-components';

import { ThemeColor, get } from '@whiteaway/ui';

export interface CircleProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The background color for the circle.
   */
  background?: ThemeColor;
  /**
   * The color for the content inside of the circle.
   */
  color?: ThemeColor;
  /**
   * The size for the circle in pixels (both the width and the height).
   */
  size: number;
}

/**
 * The Circle component is used to center some content inside of a circle layout element.
 */
export const Circle: React.FC<CircleProps> = (props) => {
  const { children, background = 'background.default', size, color = 'text.default', ...rest } = props;

  return (
    <Root $background={background} $color={color} $size={size} {...rest}>
      {children}
    </Root>
  );
};

Circle.displayName = 'Circle';

export default Circle;

const Root = styled.div<{ $background: ThemeColor; $size: number; $color: ThemeColor }>`
  ${({ $background, $color, theme, $size }) => css`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: ${`${$size}px`};
    height: ${`${$size}px`};
    border-radius: ${theme.radii.full};
    color: ${get(theme.colors, $color)};
    background: ${get(theme.colors, $background)};
  `}
`;
