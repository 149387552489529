'use client';

import { Button, Stack, Typography } from '@whiteaway/ui';

import { Circle, FlexCenter } from '@/components';
import { ROUTES } from '@/config';
import { useTranslations } from '@/contexts';

import { NotFoundIcon } from './not-found-icon';

/**
 * This component renders a custom "Not Found" page for the application. It is used to handle
 * cases where a user navigates to a route that does not exist (404 error).
 * As the category pages didn't catch the not-found in the root correctly, and the slug solution
 * mentioned in the link didn't work either, we instead made a reusable component.
 * @https://github.com/vercel/next.js/issues/49281
 */
export const NotFound = () => {
  const { translations } = useTranslations();
  const texts = translations.components.notFound;

  return (
    <FlexCenter>
      <Stack align="center">
        <Circle size={230} background="surface.inactive">
          <NotFoundIcon size={144} />
        </Circle>

        <Typography textAlign="center" variant="h1">
          {texts.header}
        </Typography>
        <Typography textAlign="center">{texts.text}</Typography>

        {/**
         * Using the next/link component from the not-found page seems to have some strange side-effects.
         * To avoid those side-effects, currently the solution is to use a conventional link element and refresh the route history.
         *
         * The specific issue we are seeing could not be found on the vercel/next github issues board,
         * however there are some that are somewhat reletated potentially.
         *
         * Similar issues:
         * https://github.com/vercel/next.js/issues/47862
         * https://github.com/vercel/next.js/issues/49372
         */}
        <Button size="lg" asElement="a" href={ROUTES.PROTECTED.HOME}>
          {texts.button}
        </Button>
      </Stack>
    </FlexCenter>
  );
};

export default NotFound;
