'use client';

import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { translations as translationsObject } from './translations';

export interface TranslationsContextProps extends PropsWithChildren {}

export interface TranslationsContextValues {
  translations: typeof translationsObject;
}

export const TranslationsContext = createContext({} as TranslationsContextValues);

export const TranslationsProvider = (props: TranslationsContextProps): React.ReactElement => {
  const { children } = props;

  const [translations] = useState(translationsObject);

  return <TranslationsContext.Provider value={{ translations }}>{children}</TranslationsContext.Provider>;
};

export const useTranslations = (): TranslationsContextValues => {
  return useContext(TranslationsContext);
};
