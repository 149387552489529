'use client';

import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * The component is used to center its content both horizontally and vertically
 */
export const FlexCenter: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return <Root {...rest}>{children}</Root>;
};

const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
